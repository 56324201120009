<template>
  <section class="section has-background-white">
    <div class="loading is-loading" v-if="loading">
      Loading...
    </div>

    <div class="notification is-warning has-text-white" v-if="error">
      Blogs cannot be retrieved, error is: {{ error }}
    </div>

    <div v-if="pageData">
      <div class="container has-text-centered" v-if="pageData">
        <h1 class="title is-size-2 has-text-weight-light is-spaced has-space-below">
          {{ pageData.title }}
        </h1>
        <h2 class="subtitle is-size-4 has-dbl-space-below">
          Posted on
          <time :datetime="pageData.publishDate">{{ displayDate(pageData.publishDate) }}</time>
        </h2>
      </div>
      <div class="container has-dbl-space-below">
        <figure class="image is-3by1">
          <img
              :src="pageData.heroImage.fields.file.url + '?fit=scale&w=480&h=160'"
              :srcset="`${pageData.heroImage.fields.file.url}?w=640&h=214&fit=fill 640w,
                      ${pageData.heroImage.fields.file.url}?w=960&h=320&fit=fill 960w,
                      ${pageData.heroImage.fields.file.url}?w=1920&h=640&fit=fill 1920w`"
              sizes="calc(100vw - 64px)"
              :alt="pageData.heroImage.fields.description"
          >
        </figure>
      </div>
      <div class="container">
        <rich-text :document="pageData.bodytext"></rich-text>
        <div class="media">
          <div class="media-left">
            <figure class="image is-48x48">
              <img :src="pageData.author.fields.image.fields.file.url + '?fit=thumb&w=48'"
                   :alt="pageData.author.fields.name">
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-6">{{ pageData.author.fields.name }}</p>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
// get some convenience functions e.g. displayDate and convertToHTML
import misc from '../mixins/misc'
import RichText from '@/components/RichText.vue'

export default {
  name: 'BlogItem',
  mixins: [misc],
  props: {
    slug: String //for an individual item we only look at the slug to find it
  },
  data: function () {
    return {
      loading: false,
      error: null,
      pageData: null, //NB v-if uses this to hide div when no data
      // SEO fields
      title: 'Untangl Blog Article',
      description: 'Blog article description',
      robots: 'index,follow'
    }
  },
  components: {
    RichText
  },
  created() {
    this.getBlogItem(this.$route.params.slug);
  },
  methods: {

    getBlogItem: function (slug) {
      this.$log.debug('{Blog} getBlogs called');
      this.error   = this.pageData = null;
      this.loading = true;

      const query = {
        'content_type': process.env.VUE_APP_ARTICLE_TYPE_ID,
        'fields.slug': slug,
      };

      this.$contentful
          .getEntries(query)
          .then((res) => {
            this.pageData          = res.items[0].fields;
            // this.pageData.bodyHTML = this.convertToHTML(this.pageData.bodytext);
            // this.pageData.bodyHTML = documentToHtmlString(this.pageData.bodytext, options);
            this.loading           = false;

            //Save SEO data if there is any
            let fields = res.items && res.items[0] && res.items[0].fields ?
                res.items[0].fields :
                {};
            if (fields.title) {
              this.title = fields.title;
            }
            if (fields.seoDescription) {
              this.description = fields.seoDescription;
            }
            if (fields.seoRobots) {
              this.robots = fields.seoRobots;
            }
          })
          .catch((error) => {
            this.$log.debug('{Blog} getBlogs failed with error:', JSON.stringify(error, null, 2));
            this.loading = false;
            this.error   = error;
          })
    }
  },

  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          property: 'og:title',
          content: this.title,
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.description,
          vmid: 'og:description'
        },
        {
          vmid: 'description',
          name: 'description',
          content: this.description
        },
        {
          vmid: 'robots',
          name: 'robots',
          content: this.robots
        }
      ]
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

/*@import "~bulma/sass/utilities/mixins";*/
/*@import "@/scss/_bulmaOverridden";*/

</style>
